import { Validator } from "../FormInput";
import calculateSettlement from "./calculateSettlement";

function validReimbursement(r: Reimbursement.Reimbursement) {
  const validators = [
    new Validator(r.name, { presence: true }),
    new Validator(r.account_name, { presence: true }),
    new Validator(r.account_number, {
      presence: true,
      validations: ["accountNumber"]
    })
  ];

  return validators.filter((v) => !v.valid()).length === 0;
}

function validSum(state: Reimbursement.State) {
  return calculateSettlement(state).remaining >= 0;
}

export function valid(state: Reimbursement.State): boolean {
  return validReimbursement(state.reimbursement) && validSum(state);
}

export function validDraft(state: Reimbursement.State): boolean {
  return validSum(state) && state.reimbursement.name ? true : false;
}
