import { default as ReactDatePicker, registerLocale } from "react-datepicker";
import { formatISO } from "date-fns";
import { nb } from "date-fns/locale/nb";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import useInput, { nameToId, textFieldInitializer } from "./useInput";

registerLocale("nb", nb);

function parseDate(value: string | null): Date | null {
  const timestamp = Date.parse(value);
  return !isNaN(timestamp) ? new Date(timestamp) : null;
}

export default function DatePicker(props: FormInput.DatePicker) {
  const {
    autoFocus,
    description,
    disabled,
    label,
    name,
    readonly,
    validations
  } = props;

  const { value, setValue, listeners, error } = useInput<FormInput.DatePicker>(
    props,
    textFieldInitializer(props)
  );
  const date = parseDate(value);

  const handleChange = (newDate: Date) => {
    if (newDate) {
      setValue(formatISO(newDate, { representation: "date" }));
    } else {
      setValue(null);
    }
  };

  const minDate = validations.indexOf("futureDate") !== -1 ? new Date() : null;

  return (
    <Field type="text date-picker" size={props.size}>
      <Label
        htmlFor={nameToId(name)}
        label={`${label}:`}
        tooltip={props.tooltip}
      />
      <ReactDatePicker
        id={nameToId(name)}
        autoFocus={autoFocus}
        dateFormat="dd.MM.yyyy"
        disabled={disabled}
        locale="nb"
        name={name}
        selected={date}
        onChange={handleChange}
        readOnly={readonly}
        className={error ? "warn" : ""}
        minDate={minDate}
        customInput={
          <input
            type="text"
            {...listeners}
            ref={listeners.ref as React.RefObject<HTMLInputElement>}
          />
        }
      />
      <Error error={error} />
      {description && !error && (
        <div className="description">{description}</div>
      )}
    </Field>
  );
}
