import { BigNumber } from "bignumber.js";

export default function calculateSettlement({
  decision,
  reimbursement
}: Reimbursement.State): Reimbursement.Settlement {
  const decisionSum = BigNumber(decision.sum);
  const reportedCost = BigNumber(reimbursement.reported_cost);
  const approvedCost = BigNumber(reimbursement.approved_cost);
  const totalCost = approvedCost.isNaN() ? reportedCost : approvedCost;

  const adjustedGrant = BigNumber(reimbursement.adjusted_grant);

  const calculatedGrant = BigNumber.minimum(
    totalCost.times(100 - decision.deductible).dividedBy(100),
    decisionSum
  );

  const grant = adjustedGrant.isNaN() ? calculatedGrant : adjustedGrant;

  const adjustedDeductible = BigNumber(reimbursement.adjusted_deductible_sum);
  const calculatedDeductible = totalCost.minus(grant);
  const deductible = adjustedDeductible.isNaN()
    ? calculatedDeductible
    : adjustedDeductible;

  const deductiblePercent = deductible.dividedBy(totalCost).multipliedBy(100);

  const advancePaid = BigNumber(reimbursement.advance_paid || 0);
  const calculatedSum = grant.minus(advancePaid);
  const adjustedSum = BigNumber(reimbursement.adjusted_sum);
  const sum = adjustedSum.isNaN() ? calculatedSum : adjustedSum;

  const remaining = decisionSum.minus(sum).minus(advancePaid);

  return {
    grant: calculatedGrant,
    deductible: calculatedDeductible,
    deductiblePercent: deductiblePercent,
    sum: calculatedSum,
    remaining: remaining
  };
}
