import { useReducer } from "react";
import { BigNumber } from "bignumber.js";

function reducer(
  state: Reimbursement.State,
  action: Reimbursement.Action
): Reimbursement.State {
  switch (action.type) {
    case "update":
      return {
        ...state,
        reimbursement: { ...state.reimbursement, ...action.payload }
      };
    case "setAttachments":
      return { ...state, attachments: action.payload };
  }
}

function buildDecision(
  arg?: Partial<Reimbursement.Decision>
): Reimbursement.Decision {
  const decision = {
    deductible: arg?.deductible || 0,
    sum: arg?.sum || 0
  };
  const totalCost = BigNumber(decision.sum).dividedBy(
    BigNumber(100 - decision.deductible).dividedBy(100)
  );
  return { ...decision, totalCost: totalCost.toFixed() };
}

function numberString(value: number | null) {
  if (value === null) {
    return "";
  } else {
    return `${value}`;
  }
}

function initState(resource: Reimbursement.Resource): Reimbursement.State {
  return {
    attachments: resource.attachments || [],
    decision: buildDecision(resource.decision),
    reimbursement: {
      name: resource.name || "",
      account_name: resource.account_name || "",
      account_number: resource.account_number || "",
      comments: resource.comments || "",
      approved_cost: numberString(resource.approved_cost),
      adjusted_deductible_sum: numberString(resource.adjusted_deductible_sum),
      adjusted_grant: numberString(resource.adjusted_grant),
      advance_paid: numberString(resource.advance_paid),
      adjusted_sum: numberString(resource.adjusted_sum),
      reported_cost: numberString(resource.reported_cost),
      case_id: resource.case_id
    }
  };
}

export default function useReimbursementForm(
  resource: Reimbursement.Resource
): [Reimbursement.State, React.Dispatch<Reimbursement.Action>] {
  const [state, dispatch] = useReducer(reducer, resource, initState);
  return [state, dispatch];
}
