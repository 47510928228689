type Value = string | number | boolean;

type ValidatorOptions = {
  validations?: FormInput.Validation[];
  presence?: boolean;
  optional?: boolean;
  limit?: number;
};

export default class Validator<T = Value> {
  opts: ValidatorOptions;
  validations: FormInput.Validation[];
  value: T;

  constructor(value: T, opts: ValidatorOptions) {
    this.value = value;
    this.opts = opts || {};
    this.validations = opts.validations || [];
  }

  accountNumber() {
    if (this.noMatch(/^\d\d\d\d\d\d\d\d\d\d\d$/)) {
      return "Må være et kontonummer";
    }
    return false;
  }

  email() {
    if (this.noMatch(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i)) {
      return "Ikke en gyldig epost-adresse";
    }
    return false;
  }

  futureDate() {
    return false;

    // if (typeof this.value !== "string") {
    //   return false;
    // }
    // const timestamp = Date.parse(this.value);
    // if (!isNaN(timestamp) && new Date(timestamp) < new Date()) {
    //   return "må være en fremtidig dato";
    // }
  }

  password() {
    if (typeof this.value !== "string") {
      return false;
    }
    if (this.value && this.stringValue().length < 10) {
      return "Er for kort (minimum 10 tegn)";
    }
    return false;
  }

  errors(): string[] {
    return [
      this.presence(),
      this.length(),
      ...this.validations.map((v) => this[v]())
    ].filter((m) => m) as string[];
  }

  length() {
    if (typeof this.value !== "string") {
      return false;
    }
    const value = this.value;
    const length = value.length + (value.split("\n").length - 1);
    if ("limit" in this.opts && length > this.opts.limit) {
      return `Kan ikke være lengre enn ${this.opts.limit} tegn`;
    }
    return false;
  }

  noMatch(expression: RegExp, str?: string) {
    const value = str || this.stringValue();
    return value && typeof value == "string" && !value.match(expression);
  }

  decimal() {
    if (this.noMatch(/^-?[\d.]+$/)) {
      return "Må være et tall";
    }
    return false;
  }

  number() {
    if (this.noMatch(/^-?[\d]+$/)) {
      return "Må være et nummer";
    }
    return false;
  }

  organizationNumber() {
    if (this.noMatch(/^\d\d\d\d\d\d\d\d\d$/)) {
      return "Må være et organisasjonsnummer";
    }
    return false;
  }

  postalCode() {
    if (this.noMatch(/^\d\d\d\d$/)) {
      return "Må være et postnummer";
    }
    return false;
  }

  presence() {
    if (this.opts.presence && !this.opts.optional && !this.value) {
      return "Må fylles inn";
    }
    return false;
  }

  year() {
    if (this.noMatch(/^\d\d\d\d$/)) {
      return "Må være et årstall";
    }
    return false;
  }

  stringValue() {
    if (typeof this.value == "string") {
      return this.value;
    }
    return "";
  }

  valid() {
    return this.errors().length == 0;
  }
}
